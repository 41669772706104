<template>
  <b-card>
    <div>
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        경기별 배팅 리스트
        <b-badge
          variant="light-primary"
          style="font-size: small; margin-left: 5px; margin-right: 3px"
          class="badge-glow"
        >
          {{ totalRows }}건
        </b-badge>
        <b-badge
          variant="light-info"
          style="font-size: small; margin-right: 3px"
        >
          실시간
        </b-badge>
        <b-badge
          variant="primary"
          style="font-size: x-small"
        >
          DB 연계
        </b-badge>
      </h4>
      <hr>
      <div class="ml-2 mr-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1"
          >
            <label style="width: 60px">상태</label>
            <b-form-select
              id="site-select"
              v-model="fetchSelected1"
              :options="option1"
              class="divSelecter font-small-3"
              size="sm"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              class="mb-1"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!--                </div>-->
          </b-col>
        </b-row>
      </div>
      <div class="ml-2 mr-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1"
          >
            <label style="width: 60px">카테고리</label>
            <b-form-select
              id="site-select"
              v-model="fetchSelected2"
              :options="option2"
              class="divSelecter font-small-3"
              size="sm"
            />
          </b-col>
          <b-col
            cols="6"
            lg="6"
            md="6"
            sm="12"
            class=""
          >
            <b-form-group
              class="font-small-3"
              label="배팅일시"
              label-for="period"
              label-cols-md="2"
            >
              <b-input-group
                name="period"
                class="calendar-size font-small-3"
              >
                <b-form-datepicker
                  v-model="periodFrom"
                  class=""
                  boundary="window"
                  locale="ko"
                  placeholder="시작일"
                  aria-controls="example-input"
                  size="sm"
                  :max="periodTo"
                />
                <div class="pl-1 pr-1">
                  ~
                </div>
                <b-form-datepicker
                  v-model="periodTo"
                  class=""
                  boundary="window"
                  locale="ko"
                  placeholder="종료일"
                  aria-controls="example-input"
                  size="sm"
                  :min="periodFrom"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <!-- Per Page -->
      <div class="ml-2 mr-2 mb-1">
        <b-row>
          <b-col
            cols="6"
            md="6"
            class="col-sm-12"
          >
            <label style="width: 53px">Show</label>
            <b-form-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              size="sm"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
        </b-row>
      </div>
      <b-table
        responsive
        show-empty
        hover
        small
        empty-text="No matching records found"
        :per-page="perPage"
        :items="fetchBettingListByMatch"
        :fields="fields1"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        style="text-align: center; font-size: small"
        :current-page="currentPage"
        @filtered="onFiltered"
        @row-clicked="rowClickHandler1"
      >
        <template
          #cell(index)="data"
          size="sm"
          style="text-align: center; min-width: 3rem"
        >
          {{ totalRows - (perPage * (currentPage - 1) + data.index) }}
        </template>
        <template #cell(site)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 4rem"
          >
            {{ data.item.site }}
          </div>
        </template>
        <template #cell(gameDate)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 9rem"
          >
            {{ data.item.gameDate }}
          </div>
        </template>
        <template #cell(category)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 5rem"
          >
            {{ categoryText(data.item.category) }}
          </div>
        </template>
        <template #cell(oddsType)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 5rem"
          >
            {{ oddsTypeText(data.item.oddsType) }}
          </div>
        </template>
        <template #cell(leagueName)="data">
          <div
            size="sm"
            class="text-truncate"
            style="text-align: left; max-width: 10rem"
            v-b-tooltip.hover.bottom.v-danger="data.item.leagueName"
          >
            {{ data.item.leagueName }}
          </div>
        </template>
        <template #cell(homeName)="data">
          <div
            size="sm"
            class="text-truncate"
            style="text-align: right; float: right; max-width: 10rem;"
            v-b-tooltip.hover.bottom.v-danger="data.item.homeName"
          >
            {{ data.item.homeName }}
          </div>
        </template>
        <template #cell(oddsHome)="data">
          <div
            size="sm"
            style="min-width: 2rem; color: yellow"
          >
            {{ formatNumber(data.item.oddsHome) }}
          </div>
        </template>
        <template #cell(handicap)="data">
          <div
            v-if="data.item.handicap"
            size="sm"
            style="text-align: center; min-width: 3rem; color: yellow"
          >
            {{ data.item.handicap }}
          </div>
          <div
            v-else-if="data.item.oddsDraw"
            size="sm"
            style="text-align: center; min-width: 3rem; color: yellow"
          >
            {{ formatNumber(data.item.oddsDraw) }}
          </div>
          <div
            v-else
            size="sm"
            style="text-align: center; min-width: 3rem"
          >
            VS
          </div>
        </template>
        <template #cell(oddsAway)="data">
          <div
            size="sm"
            style="min-width: 2rem; color: yellow"
          >
            {{ formatNumber(data.item.oddsAway) }}
          </div>
        </template>
        <template #cell(awayName)="data">
          <div
            size="sm"
            class="text-truncate"
            style="text-align: left; max-width: 10rem;"
            v-b-tooltip.hover.bottom.v-danger="data.item.awayName"
          >
            {{ data.item.awayName }}
          </div>
        </template>
        <template #cell(betCashHome)="data">
          <div
            size="sm"
            style="text-align: right; min-width: 4rem; color:rgb(196,186,138)"
          >
            {{ Number(data.item.betCashHome).toLocaleString() }}
          </div>
        </template>
        <template #cell(betCashTie)="data">
          <div
            size="sm"
            style="text-align: right; min-width: 4rem; color:rgb(196,186,138)"
          >
            {{ Number(data.item.betCashTie).toLocaleString() }}
          </div>
        </template>
        <template #cell(betCashAway)="data">
          <div
            size="sm"
            style="text-align: right; min-width: 4rem; color:rgb(196,186,138)"
          >
            {{ Number(data.item.betCashAway).toLocaleString() }}
          </div>
        </template>
        <template #cell(totalOdds)="data">
          <div
            size="sm"
            style="text-align: right; min-width: 3rem; color:rgb(196,186,138)"
          >
            {{ Number(data.item.totalOdds).toLocaleString() }}
          </div>
        </template>
        <template #cell(totalHitCash)="data">
          <div
            size="sm"
            style="text-align: right; min-width: 4rem; color:rgb(196,186,138)"
          >
            {{ Number(data.item.totalHitCash).toLocaleString() }}
          </div>
        </template>
        <template #cell(count)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 2rem"
          >
            {{ data.item.count }}
          </div>
        </template>
        <template #cell(betResult)="data">
          <div
            v-if="data.item.betResult === '배팅가능'"
            size="sm"
            style="text-align: center; min-width: 4rem; color: limegreen"
          >
            {{ data.item.betResult }}
          </div>
          <div
            v-if="data.item.betResult === '배팅마감'"
            size="sm"
            style="text-align: center; min-width: 4rem; color: red"
          >
            {{ data.item.betResult }}
          </div>
        </template>
        <template
          v-if="fetchBettingListByMatch.length > 0"
          slot="bottom-row"
        >
          <td
            v-if="fetchSelected1 === 'REQUEST'"
            style="background-color: #24233a; color:rgb(196,186,138)"
          />
          <td
            colspan="12"
            style="background-color: #24233a; color:rgb(196,186,138)"
          >
            Total
          </td>
          <td
            class="text-center"
            style="background-color: #24233a; color:rgb(196,186,138)"
          >
            {{ Number(getTotal('count')).toLocaleString() }}
          </td>
          <td
            class="text-right"
            style="background-color: #24233a; color:rgb(196,186,138)"
          >
            {{ Number(getTotal('betCashHome')).toLocaleString() }}
          </td>
          <td
            class="text-right"
            style="background-color: #24233a; color:rgb(196,186,138)"
          >
            {{ Number(getTotal('betCashTie')).toLocaleString() }}
          </td>
          <td
            class="text-right"
            style="background-color: #24233a; color:rgb(196,186,138)"
          >
            {{ Number(getTotal('betCashAway')).toLocaleString() }}
          </td>
        </template>
        <template #row-details="row">
          <b-card
            class="p-0"
            style="background-color: #212026;"
          >
            <BettingListByMatchDetails :record="row.item._detailsData" :homeAway="detailHomeAway"/>
          </b-card>
        </template>
      </b-table>
      <!--          <pre>selected: {{ selectedGroupIds }}</pre>-->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BTable,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BBadge,
  BFormCheckbox,
  BFormDatepicker, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'
import {
  FETCH_BETTING_LIST_BY_MATCH,
} from '@/store/result/action'
import * as moment from 'moment-timezone'
import BettingListByMatchDetails from '@/views/game/gameDeails/BettingListByMatchDetails'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'
const fmt2 = 'YYYYMMDD'

const resultStore = createNamespacedHelpers('resultStore')

export default {
  components: {
    BettingListByMatchDetails,
    BTabs,
    BTab,
    BRow,
    BCard,
    BTable,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BPagination,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BBadge,
    BFormCheckbox,
    BFormDatepicker,
    VBTooltip,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      tab: '',
      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      siteSelected: localStorage.getItem('authSiteSelected'),
      userIp: localStorage.getItem('ip'),
      fetchSelected1: '',
      fetchSelected2: '',
      option1: [
        { value: 'ALL', text: '전체' },
        { value: 'YET', text: '배팅가능' },
        { value: 'CLOSED', text: '마감' },
      ],
      option2: [
        { value: 'ALL', text: '전체' },
        { value: 'Sports-Prematch', text: '프리매치' },
        { value: 'Sports-Special', text: '스페셜' },
        { value: 'Sports-Inplay', text: '인플레이' },
      ],
      option3: [
        { value: 'Match Winner', text: '승무패' },
        { value: 'Home/Away', text: '승무패' },
        { value: 'Asian Handicap', text: '핸디캡' },
        { value: 'Asian Handicap (Games)', text: '핸디캡' },
        { value: 'Maps Handicap', text: '핸디캡' },
        { value: 'Goals Over/Under', text: '오버언더' },
        { value: 'Over/Under', text: '오버언더' },
        { value: 'Over/Under by Games in Match', text: '오버언더' },
        { value: 'Total Maps', text: '오버언더' },
      ],
      perPage: 30,
      pageOptions: [10, 30, 50],
      totalRowsFinish: 1,
      totalRows: 1,
      notReadCnt: 0,
      currentPage: 1,
      currentPageFinish: 1,
      sortBy: 'gameDate',
      sortDesc: true,
      sortDirection: 'desc',
      sortByFinish: 'idx',
      sortDescFinish: true,
      sortDirectionFinish: 'desc',
      filter: null,
      filterOn: [],
      disabled: false,
      check1: 'false',
      isHidden1: true,
      selectedRecord: null, // 선택된 record를 저장하기 위한 변수
      isExpandChecked1: false,
      isExpandChecked2: false,
      periodFrom: '',
      periodTo: '',
      selectedGroupIds: [],
      selectedCash: 0,
      detailHomeAway: '',
      fields1: [
        {
          key: 'index', sortable: false, label: 'No.', thClass: 'col1',
        },
        // {
        //   key: 'site', sortable: false, label: '사이트', thClass: 'col1',
        // },
        {
          key: 'gameDate', sortable: false, label: '경기일시', thClass: 'col3',
        },
        {
          key: 'category', sortable: false, label: '카테고리', thClass: 'col2',
        },
        {
          key: 'sports', sortable: false, label: '구분', thClass: 'col2',
        },
        {
          key: 'oddsType', sortable: false, label: '유형', thClass: 'col4',
        },
        {
          key: 'leagueName', sortable: false, label: '리그', thClass: 'col2',
        },
        {
          key: 'homeName', sortable: false, label: '홈팀', thClass: 'col3', tdClass: 'border-left',
        },
        {
          key: 'oddsHome', sortable: false, label: '', thClass: 'col3', tdClass: '',
        },
        {
          key: 'handicap', sortable: false, label: '무/기준', thClass: 'col5', tdClass: 'border border-primary',
        },
        {
          key: 'oddsAway', sortable: false, label: '', thClass: 'col3', tdClass: '',
        },
        {
          key: 'awayName', sortable: false, label: '원정팀', thClass: 'col3', tdClass: '',
        },
        {
          key: 'betResult', sortable: false, label: '상태', thClass: 'col5', tdClass: 'border border-primary',
        },
        {
          key: 'count', sortable: true, label: '배팅', thClass: 'col3', tdClass: 'border border-primary',
        },
        {
          key: 'betCashHome', sortable: true, label: '승', thClass: 'col3', tdClass: 'border border-primary',
        },
        {
          key: 'betCashTie', sortable: true, label: '무', thClass: 'col3', tdClass: 'border border-primary',
        },
        {
          key: 'betCashAway', sortable: true, label: '패', thClass: 'col3', tdClass: 'border border-primary',
        },
      ],
    }
  },
  watch: {
    isExpandChecked1(newVal) {
      this.fetchBettingListByMatch.forEach(item => {
        this.$set(item, '_showDetails', newVal)
        this.$set(item, '_detailsData', item) // 각 항목의 상세 데이터를 설정
      })
    },
    fetchSelected1(newVal) {
      // When the selected option changes, fetch the data again with the new value
      this.fetchSelected1 = newVal
      this.fetchData1(newVal)
    },
    fetchSelected2(newVal) {
      // When the selected option changes, fetch the data again with the new value
      this.fetchSelected2 = newVal
      this.fetchData1()
    },
    periodFrom() {
      this.fetchData1(this.fetchSelected1)
    },
    periodTo() {
      this.fetchData1(this.fetchSelected1)
    },
  },
  created() {
    this.init()
    this.$socket.on('requestAlarmsBet', data => {
      console.log('requestAlarmsBet: ', data)
      this.fetchData1(this.fetchSelected1)
    })
    // this.$socket.on('requestAlarmsBetFinish', data => {
    //   console.log('requestAlarmsBetFinish: ', data)
    //   this.$store.dispatch('noticeStore/socketFetchRequestAlarmsBetFinish', data)
    // })
  },
  computed: {
    ...resultStore.mapGetters({
      fetchBettingListByMatch: 'fetchBettingListByMatch',
    }),
    oddsTypeText() {
      return value => {
        const option = this.option3.find(option => option.value === value)
        return option ? option.text : value
      }
    },
    categoryText() {
      return value => {
        const option = this.option2.find(option => option.value === value)
        return option ? option.text : value
      }
    },
  },
  mounted() {
    // 중복 Router 동작 우회로 EventBus 사용함
    // this.$EventBus.$on('QuickBtnFetchYET', param => {
    //   this.fetchSelected1 = 'ALL'
    // })
    // // 중복 Router 동작 우회로 EventBus 사용함
    // this.$EventBus.$on('QuickBtnFetchREQUEST', param => {
    //   this.fetchSelected1 = 'REQUEST'
    // })
    // 기본 조회
    // if (this.$route.params.targetOption) {
    //   this.fetchSelected1 = 'ALL'
    // } else {
    //   this.fetchSelected1 = 'ALL'
    // }
  },
  methods: {
    ...resultStore.mapActions({
      $fetchBettingListByMatch: FETCH_BETTING_LIST_BY_MATCH,
    }),
    init() {
      this.fetchSelected1 = 'ALL'
      this.fetchSelected2 = 'ALL'
      this.periodFrom = moment(new Date()).startOf('month').format(fmt1)
      this.periodTo = moment(new Date()).endOf('month').format(fmt1)
    },
    async fetchData1(fetchSelected) {
      // const mySite = 'GAEGGUL'
      // const mySite = this.userData.site
      // const mySite = this.authSite[0]
      // console.log('fetchSelected ::', fetchSelected)
      await this.$fetchBettingListByMatch({
        site: this.authSite,
        confirmed: fetchSelected,
        category: this.fetchSelected2,
        periodFrom: this.periodFrom ? (this.periodFrom).split(' ')[0] : '',
        periodTo: this.periodTo ? (this.periodTo).split(' ')[0] : '',
      })
      this.totalRows = this.fetchBettingListByMatch.length
      // console.log(this.fetchBettingListByMatch)
    },
    async rowClickHandler1(record, index, type) {
      const ariaColIndex = type.target.ariaColIndex || type.target.parentNode.ariaColIndex
      const homeAway = this.getHomeAway(record, ariaColIndex)

      if (homeAway) {
        this.detailHomeAway = homeAway
        this.$set(record, 'homeAway', this.detailHomeAway)
        this.$set(record, '_showDetails', !record._showDetails)
        this.$set(record, '_detailsData', record)
      }
    },
    getHomeAway(record, ariaColIndex) {
      const overUnderTypes = new Set([
        'Goals Over/Under',
        'Over/Under',
        'Over/Under by Games in Match',
        'Total Maps',
      ])
      if (ariaColIndex === '14') {
        return overUnderTypes.has(record.oddsType) ? 'Over' : 'Home'
      } else if (ariaColIndex === '15') {
        return 'Draw'
      } else if (ariaColIndex === '16') {
        return overUnderTypes.has(record.oddsType) ? 'Under' : 'Away'
      }
      return null
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.totalRowsFinish = filteredItems.length
      this.currentPageFinish = 1
    },
    getTotal(field) {
      return this.fetchBettingListByMatch.reduce((acc, item) => acc + item[field], 0)
    },
    toggleAllRows() {
      if (this.allSelected) {
        this.selectedGroupIds = this.fetchBettingListByMatch.map(item => item.groupId)
      } else {
        this.selectedGroupIds = []
      }
    },
    updateTotalSum(totalHitCash, groupId) {
      if (this.selectedGroupIds.includes(groupId)) {
        // groupId가 selectedGroupIds 배열에 있으면 체크된 상태
        this.selectedCash += totalHitCash
      } else {
        // 그렇지 않으면 체크 해제된 상태
        this.selectedCash -= totalHitCash
      }
    },
    formatNumber(value) {
      if (!value) return '0.00'
      return parseFloat(value).toFixed(2)
    },
  },
}

</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
.divSelecter {
  width: 180px;
}
</style>
